<template>
  <div class="adminServicesWrapper">
    <div class="homeTitle">
      <span class="animateTitle">Наши сервисы</span>
    </div>
    <div class="displayDesctop">
      <div class="d-flex flex-wrap justify-center">
        <div class="items mx-4" v-for="(item, index) in adminServiceList" :key="index">
          <ServiceItem :item="item" />
        </div>
      </div>
    </div>
    <div class="displayMobile">
      <splide :options="options">
        <splide-slide v-for="(item, index) in adminServiceList" :key="index">
          <ServiceItem :item="item" />
        </splide-slide>
      </splide>
    </div>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import ServiceItem from "./ServiceItem";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
export default {
  components: {
    Splide,
    SplideSlide,
    ServiceItem,
  },
  data() {
    return {
      options: {
        type: "loop",
        perPage: 1,
        // width  : 350,
        breakpoints: {
          600: {
            width: 400,
          },
          500: {
            width: 380,
          },
          400: {
            width: 350,
          },
          330: {
            width: 320,
          },
        },
      },
    };
  },
  computed: {
    adminServiceList() {
      return this.$store.getters.getAdminServiceList;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/adminservices.scss";
</style>
