<template>
  <div class="filterWrapper">
    <div class="filter_list">
      <span
        v-for="(item, index) in list"
        class="filter_item serviceChips"
        :class="item"
        :key="index"
        @click="activeFilter(index)"
        >{{ item | capitalize }}
      </span>
      <v-btn
        v-if="isFiltered"
        icon
        color="grey"
        class="reset"
        @click="activeFilter(4)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: ["creative", "dashboards", "dataviz", "empty"],
      isFiltered: false,
    };
  },
  filters: {
    // Регистрируем фильтр capitalize:
    capitalize: function (str) {
      return str[0].toUpperCase() + str.slice(1);
    },
  },
  methods: {
    activeFilter(index) {
      this.$store.commit("SET_FILTER", index);
      this.isFiltered = true;
      if(index == 4) this.isFiltered = false;
    },
  },
};
</script>
<style lang="scss">
.filterWrapper {
  max-width: 300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.filter_list {
  display: flex;
  position: relative;
  .filter_item {
    margin: 0 5px;
    cursor: pointer;
  }
  .reset {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>