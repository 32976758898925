<template>
  <div>
    <v-tooltip top v-if="isPined">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon @click="pinDeactivate(item)" small>
          <v-icon small color="primary">mdi-checkbox-blank-circle</v-icon>
        </v-btn>
      </template>
      <span>Открепить</span>
    </v-tooltip>
    <v-tooltip top v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon @click="pinActivate(item)" small>
          <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>Закрепить в начале</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      isPined: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const arr = localStorage.getItem("pinList");
      if (
        localStorage.getItem("pinList") != null &&
        Array.isArray(JSON.parse(localStorage.getItem("pinList")))
      ) {
        if (arr.includes(this.item.id)) this.isPined = true;
        else this.isPined = false;
      } else {
        let arr = [];
        localStorage.setItem("pinList", JSON.stringify(arr));
      }
    },
    pinActivate(item) {
      this.$store.commit("SET_PIN", item);
      this.isPined = true;
      const local = localStorage.getItem("pinList");
      let arrList = this.arrOfUnique(JSON.parse(local));
      if (arrList != null) {
        arrList.push(item.id);
        localStorage.setItem("pinList", JSON.stringify(arrList));
      } else {
        let arr = [];
        arr.push(item.id);
        localStorage.setItem("pinList", JSON.stringify(arr));
      }
      this.$emit("active-pin", item.id);
    },
    pinDeactivate(item) {
      let arrList = this.arrOfUnique(
        JSON.parse(localStorage.getItem("pinList"))
      );
      arrList = arrList.filter((el) => {
        return el != item.id;
      });
      this.isPined = false;
      localStorage.setItem("pinList", JSON.stringify(arrList));
      this.$emit("active-pin", item.id);
    },
    arrOfUnique(list) {
      return list.filter((item, index) => {
        return list.indexOf(item) === index;
      });
    },
  },
};
</script>