<template>
  <div>
    <!-- <div class="homeTitle">
      <span class="animateTitle">Наши пользователи</span>
    </div> -->
    <!-- {{ GET_USERS }} -->
    <div v-if="isloadingUsers">
      <div class="dialogWrapper">
        <div class="dialogTitle">Загрузка...</div>
        <div class="confirmBtnList">
          <button class="btn btn2" @click="reload">Обновить</button>
        </div>
      </div>
    </div>
    <div class="tableBox" v-else>
      <v-dialog v-model="dialogDelete" max-width="434px">
        <div class="dialogWrapper">
          <div class="dialogTitle">Вы уверены что хотите удалить?</div>
          <div class="confirmBtnList">
            <button class="btn" @click="deleteItemConfirm">Удалить</button>
            <button class="btn btn2" @click="closeDelete">Отмена</button>
          </div>
        </div>
      </v-dialog>
      <v-dialog v-model="dialogEdit" max-width="434px">
        <div class="dialogWrapper">
          <div class="dialogClose" @click="closeEdit">
            <v-icon>mdi-close</v-icon>
          </div>
          <div class="dialogTitle">Редактирование клиента</div>
          <div class="dialogTitle">ID: {{ userItem.id }}</div>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="userItem.name" :rules="GET_LOGIN_VALID" :counter="50" label="Имя клиента" required>
              </v-text-field>
              <v-text-field v-model="userItem.password" :rules="GET_PASS_VALID" :counter="50" label="Пароль" required>
              </v-text-field>
              <div class="confirmBtnList">
                <button class="btn" :disabled="!valid" @click.prevent="updateUser">
                  Изменить
                </button>
                <v-spacer></v-spacer>
                <v-btn icon @click.prevent="deleteItem(editingItem)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-container>
        </div>
      </v-dialog>
      <v-dialog v-model="dialogLogin" max-width="434px">
        <div class="dialogWrapper">
          <div class="dialogClose" @click="dialogLogin = false">
            <v-icon>mdi-close</v-icon>
          </div>
          <div class="d-flex justify-center">
            <div :class="drawnClass" class="d-flex align-center">
              <SvgTick />
            </div>
            <div class="dialogTitle mx-4">
              Вы залогинились под <br />
              <span class="userLogined">{{
                  userlogin || "пользователем"
              }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="dialogColumn">
            <div class="dialogTitle mb-0">Список сервисов:</div>
            <div class="d-flex justify-center" v-if="userServices">
              <a v-for="(i, index) in userServices" :key="index" :href="changeLink(i)" class="serviceChips my-2"
                :class="serviceString(i)">Go{{ serviceString(i) | capitalize }}</a>
            </div>
            <div v-else class="serviceChips my-2">Нет сервисов</div>

            <router-link to="/" tag="a" class="homeRoute mt-4">
              <span class="material-icons"> chevron_left </span>
              <span class="linkText">Страница клиента</span>
            </router-link>
          </div>
        </div>
      </v-dialog>
      <v-data-table :headers="headers" :items="GET_USERS" :search="search" :disable-pagination="true"
        hide-default-footer :dense="lineReducer" ref="dataTable" fixed-header height="550" class="dataTable">
        <template v-slot:top>
          <div class="d-flex flex-column justify-content-center">
            <div class="tableNav">
              <div class="navList">
                <label for="find" class="inputField">
                  <input class="searchField" type="text" name="find" v-model="search" placeholder="Найти" />
                  <v-icon>mdi-magnify</v-icon>
                </label>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="mx-2 displayDesctop" @click="lineReduce"
                      :color="isActive('line')">
                      <v-icon>mdi-format-line-weight</v-icon>
                    </v-btn>
                  </template>
                  <span>Уменьшить масштаб</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="reloadTable">
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Обновить</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <CreateUser />
              </div>
            </div>
            <FilterService class="mx-auto" />
          </div>

        </template>
        <template v-slot:[`item.pin`]="{ item }">
          <PinButton @active-pin="pinListed" :item="item" />
        </template>
        <template v-slot:[`item.name`]="{ item }" class="nameTableBox">
          <span class="nameTable" :class="{ pined: pinIsActive(item.id) }">{{
              item.name
          }}</span>
        </template>
        <template v-slot:[`item.editpass`]="{ item }">
          <div class="passInput" :class="{
            border: item.password,
            greenBorder: validatePassLength(item.password),
          }">
            <input type="text" v-model="item.password" placeholder="Пароль..." />

            <div class="iconChange" @click="updatePass(item)" v-if="validatePassLength(item.password)">
              <span class="material-icons"> done </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.services`]="{ item }">
          <div v-if="item.serviceLinks">
            <!-- :href="changeLink(i)" -->
            <span v-for="(i, index) in item.serviceLinks" :key="index" class="serviceChips" @click="loginUrl(i, item)"
              :class="serviceString(i)">Go{{ serviceString(i) | capitalize }}</span>
          </div>
          <div v-else>Добавлю позже</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon>
                <v-icon v-bind="attrs" v-on="on" @click.stop="editItem(item)">
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Изменить логин и пароль</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.login`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon>
                <v-icon v-bind="attrs" v-on="on" @click.stop="loginClient(item)">
                  arrow_forward_ios
                </v-icon>
              </v-btn>
            </template>
            <span>Зайти под пользователем</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CreateUser from "./CreateUser";
import SvgTick from "./SvgTick";
import PinButton from "./PinButton";
import FilterService from "./FilterService";

export default {
  components: {
    CreateUser,
    SvgTick,
    PinButton,
    FilterService,
  },
  props: ["users"],
  data: () => ({
    userlogin: "",
    drawnClass: "",
    dialogLogin: false,
    userServices: [],
    isloadingUsers: true,
    search: "",
    deletingItem: "",
    dialogDelete: false,
    lineReducer: true,
    editingItem: "",
    dialogEdit: false,
    valid: true,
    userItem: {
      id: "",
      name: "",
      password: "",
    },
    headers: [
      {
        text: "",
        align: "center",
        value: "pin",
        sortable: false,
      },
      { text: "Имя", align: "center", value: "name", sortable: false },
      { text: "Сервисы", align: "center", value: "services", sortable: false },

      {
        text: "Задать пароль",
        align: "left",
        value: "editpass",
        sortable: false,
      },
      { text: "Исправить", align: "center", value: "actions", sortable: false },
      {
        text: "Залогиниться",
        align: "center",
        value: "login",
        sortable: false,
      },
    ],
    pinListActive: [],
  }),
  watch: {
    itemPerPage(cur) {
      console.log(cur);
    },
  },
  computed: {
    ...mapGetters(["GET_USERS", "GET_LOGIN_VALID", "GET_PASS_VALID"]),
  },
  created() {
    this.initialize();
  },
  filters: {
    // Регистрируем фильтр capitalize:
    capitalize: function (str) {
      return str[0].toUpperCase() + str.slice(1);
    },
  },
  methods: {
    ...mapActions([
      "GET_USERS_FROM_API",
      "LOGIN_AS_CLIENT",
      "UPDATE_USER",
      "DELETE_CLIENT",
    ]),
    ...mapMutations(["SET_AUTH"]),
    initialize() {
      this.isloadingUsers = true;
      this.GET_USERS_FROM_API().then((el) => {
        this.isloadingUsers = false;
        if (el == false) {
          this.$store.commit("SET_LOGIN_ADMIN", false);
        }

        //проверка списка пинов и переставка на первое место
        this.pinTransform();
      });
    },
    deleteItem(item) {
      this.deletingItem = item.id;
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editingItem = item;
      this.userItem.id = item.id;
      this.userItem.name = item.name;
      this.dialogEdit = true;
    },
    loginUrl(i, item) {
      this.loginClient(item);
      setTimeout(() => {
        window.open(i, "_blank");
      }, 1000);
    },
    loginClient(item) {
      this.LOGIN_AS_CLIENT({ clientId: item.id }).then((el) => {
        if (el.status) {
          this.userlogin = item.name;
          this.userServices = item.serviceLinks;
          document.cookie = "client_login=logined";
          this.addClass();
          this.SET_AUTH(true);
          this.dialogLogin = true;
        }
      });
    },
    updatePass(item) {
      this.userItem = item;
      this.updateUser();
    },
    updateUser() {
      this.UPDATE_USER(this.userItem).then((el) => {
        if (el.status) {
          this.initialize();
          this.closeEdit();
        }
      });
    },

    deleteItemConfirm() {
      this.DELETE_CLIENT({
        id: this.deletingItem,
      }).then(() => {
        this.initialize();
        this.closeDelete();
        this.closeEdit();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.dialogEdit = false;
    },
    reload() {
      location.reload(true);
    },
    validatePassLength(pass) {
      if (pass) {
        if (pass.length > 7) {
          return true;
        }
      }
    },
    serviceString(str) {
      return str
        .split("/")
        .filter((el) => el.includes("."))[0]
        .split(".")[0];
    },
    addClass() {
      this.drawnClass = "";
      setTimeout(() => {
        this.drawnClass = "drawn";
      }, 200);
    },
    changeLink(url) {
      if (url.includes("dashboards.gomobile.ru/view/")) {
        return url + "id";
      } else {
        return url;
      }
    },
    lineReduce() {
      this.lineReducer = !this.lineReducer;
    },
    reloadTable() {
      this.initialize();
    },
    isActive(val) {
      if (val == "line") {
        return this.lineReducer ? "primary" : "gray";
      }
    },
    pinTransform() {
      if (!localStorage.getItem("pinList")) {
        this.emptyPinListLocal();
      }
      const arr = localStorage.getItem("pinList");
      if (arr[0] == "[" && arr[arr.length - 1] == "]") {
        let arrList = JSON.parse(localStorage.getItem("pinList"));
        if (arrList != null && Array.isArray(arrList)) {
          let arrList = JSON.parse(localStorage.getItem("pinList"));
          arrList = this.arrOfUnique(arrList);
          this.pinListActive = arrList;
          arrList.forEach((element) => {
            let item = this.GET_USERS.find((item) => item.id == element);
            if (item) this.$store.commit("SET_PIN", item);
          });
        }
      } else {
        this.emptyPinListLocal();
      }
    },
    emptyPinListLocal() {
      let arr = [];
      localStorage.setItem("pinList", JSON.stringify(arr));
    },
    pinListed(id) {
      if (!this.pinListActive.includes(id)) {
        this.pinListActive.push(id);
        const wr = document.querySelector(".v-data-table__wrapper");
        wr.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        this.pinListActive = this.pinListActive.filter((el) => {
          return el != id;
        });
      }
    },
    arrOfUnique(list) {
      return list.filter((item, index) => {
        return list.indexOf(item) === index;
      });
    },
    pinIsActive(id) {
      if (this.pinListActive.includes(id)) return true;
      else return false;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/tablelist.scss";
</style>
