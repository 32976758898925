<template>
  <div class="homeMain">
    <div v-if="$store.getters.ADMIN_ISLOGIN">
      <div class="adminMainWrapper">
        <div class="tableWrapper">
          <TableList />
        </div>
        <div class="tableWrapper">
          <AdminServices />
        </div>
      </div>
    </div>
    <div v-else>
      <WelcomeUserPage :itemData="itemData" />
    </div>
  </div>
</template>
<script>
import TableList from "../components/TableList";
import AdminServices from "../components/AdminServices";
import WelcomeUserPage from "@/components/WelcomeUserPage";
export default {
  components: {
    TableList,
    AdminServices,
    WelcomeUserPage,
  },
  data: () => ({
    itemData: {
      welcometext: "в интерфейс управления клиентов!",
      description: ` Здесь вы сможете задать логин и пароль для вашего 
        клиента.
        Пожалуйста залогиньтесь`,
      isAdmin: true,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      const cook = this.getCookie("auth");
      if (cook && cook != "false") {
        this.$store.commit("SET_LOGIN_ADMIN", true)
      }
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
            "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/admin.scss";
</style>
