<template>
  <v-dialog v-model="dialog" max-width="434px">
    <template v-slot:activator="{ on, attrs }">
      <button class="addUser btn" v-bind="attrs" v-on="on">
        <span class="displayDesctop">Добавить</span>
        <v-icon dark>mdi-plus</v-icon>
      </button>
      
    </template>
    <div class="dialogWrapper" max-width="434px">
      <div class="dialogClose" @click="dialog = false">
         <v-icon >mdi-close</v-icon>
      </div>
      <div class="dialogTitle">Добавление клиента</div>
      <div class="dialogForm">
        <v-container>
          <p class="error" v-if="error.length">{{ error }}</p>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="userItem.name"
              :rules="GET_LOGIN_VALID"
              :counter="50"
              label="Имя клиента"
              required
            ></v-text-field>
            <v-text-field
              v-model="userItem.password"
              :rules="GET_PASS_VALID"
              :counter="50"
              label="Пароль"
              required
            ></v-text-field>
            <div
              class="confirmBtnList"
              v-if="userItem.name && userItem.password"
            >
              <button
                :disabled="!valid"
                class="btn"
                @click.prevent="createUser"
              >
                Создать
              </button>
            </div>
          </v-form>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    error: "",
    dialog: false,
    valid: true,
    userItem: {
      id: "",
      name: "",
      password: "",
    },
  }),
  computed: {
    ...mapGetters(["GET_LOGIN_VALID", "GET_PASS_VALID"]),
  },
  methods: {
    ...mapActions(["GET_USERS_FROM_API", "CREATE_USER"]),
    createUser() {
      this.$refs.form.validate();
      this.CREATE_USER({
        id: "string",
        name: this.userItem.name,
        password: this.userItem.password,
      }).then(() => {
        this.close();
        this.userItem.name = "";
        this.userItem.password = "";
        this.GET_USERS_FROM_API();
      });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/createUser.scss";
</style>
